.particleStyle {
    z-index: 0;
    height: 100% !important;
    width: 100% !important;
    background-size: cover;
    position: absolute;
  }

/* FONT FACE */
@font-face {
    font-family: "digital";
    src: url('./images/digital-7.ttf');
  }
  /* @font-face {
    font-family: "acumin";
    src: url('./images/Acumin-RPro.otf');
  } */

/* KEYFRAMES FOR ALL DEVICE */

    @keyframes fading {
        from { opacity: 1 }
        to { opacity: 0 }
    }
    @-webkit-keyframes fading {
        from { opacity: 1 }
        to { opacity: 0 }
    }
    @-moz-keyframes fading {
        from { opacity: 1 }
        to { opacity: 0 }
    }
    @-ms-keyframes fading {
        from { opacity: 1 }
        to { opacity: 0 }
    }
    @-o-keyframes fading {
        from { opacity: 1 }
        to { opacity: 0 }
    }

    @keyframes hafiz-roket {
        0% {opacity: 0;}
        100%{opacity: 1;}
    }
    @-webkit-keyframes hafiz-roket {
        0% {opacity: 0;}
        100%{opacity: 1;}
    }
    @-moz-keyframes hafiz-roket {
        0% {opacity: 0;}
        100%{opacity: 1;}
    }
    @-ms-keyframes hafiz-roket {
        0% {opacity: 0;}
        100%{opacity: 1;}
    }
    @-o-keyframes hafiz-roket {
        0% {opacity: 0;}
        100%{opacity: 1;}
    }

  @keyframes cloud-animation {
    0%{}
    50%{ 
      opacity: 0.5;
      transform: translate(0px, 40px); 
    }
  }
  @-webkit-keyframes cloud-animation {
    0%{}
    50%{ 
      opacity: 0.5;
      transform: translate(0px, 40px); 
    }
  }
  @-moz-keyframes cloud-animation {
    0%{}
    50%{ 
      opacity: 0.5;
      transform: translate(0px, 40px); 
    }
  }
  @-ms-keyframes cloud-animation {
    0%{}
    50%{ 
      opacity: 0.5;
      transform: translate(0px, 40px); 
    }
  }
  @-o-keyframes cloud-animation {
    0%{}
    50%{ 
      opacity: 0.5;
      transform: translate(0px, 40px); 
    }
  }
  @keyframes cloud-above-animation {
    0%{}
    50%{ 
      opacity: 0.5;
      transform: translate(20px, 0px); 
    }
  }
  @-webkit-keyframes cloud-above-animation {
    0%{}
    50%{ 
      opacity: 0.5;
      transform: translate(20px, 0px); 
    }
  }
  @-moz-keyframes cloud-above-animation {
    0%{}
    50%{ 
      opacity: 0.5;
      transform: translate(20px, 0px); 
    }
  }
  @-ms-keyframes cloud-above-animation {
    0%{}
    50%{ 
      opacity: 0.5;
      transform: translate(20px, 0px); 
    }
  }
  @-o-keyframes cloud-above-animation {
    0%{}
    50%{ 
      opacity: 0.5;
      transform: translate(20px, 0px); 
    }
  }
  /* ======BOUNCE===== */
  @keyframes bounce {
    0%{}
    50%{ transform: translate(0, 15px); }
  }
  @-webkit-keyframes bounce {
    0%{}
    50%{ transform: translate(0, 15px); }
  }
  @-moz-keyframes bounce {
    0%{}
    50%{ transform: translate(0, 15px); }
  }
  @-ms-keyframes bounce {
    0%{}
    50%{ transform: translate(0, 15px); }
  }
  @-o-keyframes bounce {
    0%{}
    50%{ transform: translate(0, 15px); }
  }
  /* ======BOUNCE===== */

  @keyframes balingbaling-animation {
    from {
       transform: rotate(0deg);  
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
    }
    to {
       transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      -moz-transform: rotate(359deg);
      -ms-transform: rotate(359deg);
      -o-transform: rotate(359deg);
    }
  }
  @keyframes fade-in22 {
    0% { opacity: 1;}
    50% {
        /* transform: translateY(-70px); */
        opacity: 1;
    }
    80%{
        opacity: 1;
    }
    100% {
        /* transform: translateY(0px); */
        opacity: 0;
    }
}
  @keyframes fade-in {
        0% { opacity: 0;}
        20% {opacity: 1;}
        80%{opacity: 1;}
        100% {opacity: 0;}
    }
    @keyframes fade-in-desktop {
        0% { opacity: 1;}
        80% {
            transform: translateY(0px);
            opacity: 0;
        }
        100% {
            transform: translateY(-70px);
            opacity: 0;
        }
    }
    @keyframes fade-in-mobile {
        0% { opacity: 1;}
        80% {
            transform: translateY(-10%);
            opacity: 0;
        }
        100% {
            transform: translateY(10%);
            opacity: 0;
        }
    }
    @keyframes scale { 
        0% {transform: scale(0);}
        50%{transform: scale(1.5);}
        100%{transform: scale(1);}
    }
    @-webkit-keyframes scale { 
        0% {transform: scale(0);}
        50%{transform: scale(1.5);}
        100%{transform: scale(1);}
    }
    @-moz-keyframes scale { 
        0% {transform: scale(0);}
        50%{transform: scale(1.5);}
        100%{transform: scale(1);}
    }
    @-ms-keyframes scale { 
        0% {transform: scale(0);}
        50%{transform: scale(1.5);}
        100%{transform: scale(1);}
    }
    @-o-keyframes scale { 
        0% {transform: scale(0);}
        50%{transform: scale(1.5);}
        100%{transform: scale(1);}
    }
    @keyframes talk-button { 
        0% {transform: scale(1);}
        50%{transform: scale(1.5);}
        100%{transform: scale(1);}
    }
    @-webkit-keyframes talk-button { 
        0% {transform: scale(1);}
        50%{transform: scale(1.5);}
        100%{transform: scale(1);}
    }
    @-moz-keyframes talk-button { 
        0% {transform: scale(1);}
        50%{transform: scale(1.5);}
        100%{transform: scale(1);}
    }
    @-ms-keyframes talk-button { 
        0% {transform: scale(1);}
        50%{transform: scale(1.5);}
        100%{transform: scale(1);}
    }
    @-o-keyframes talk-button { 
        0% {transform: scale(1);}
        50%{transform: scale(1.5);}
        100%{transform: scale(1);}
    }
    @keyframes cube {
        from {
          transform: scale(0) rotate(0deg) translate(-50%, -50%);
          opacity: 1;
        }
        to {
          transform: scale(20) rotate(960deg) translate(-50%, -50%);
          opacity: 0;
        }
      }
      
    .footer {
       position: fixed;
       left: 0;
       bottom: 0;
       width: 100%;
       background-color: #1e1c27;
       color: white;
       text-align: center;
    }
    .absolute{position: absolute !important;}
    .hide-desktop {
        visibility: hidden;
    }
    /* .hide-mobile {
        visibility: visible;
    } */
    
        /* (1920x1080) Full HD Display */
        /* KEYFRAMES FOR min-width: 1920px */
      /* ========== SLIDE STYLE ============*/
      .carousel.carousel-slider{
          height: 100% !important;
          -webkit-height: 100% !important;
          -moz-height: 100% !important;
          -ms-height: 100% !important;
          -o-height: 100% !important;
          position: absolute !important;
      }
      .carousel .slider-wrapper {
          height: 100% !important;
          -webkit-height: 100% !important;
          -moz-height: 100% !important;
          -ms-height: 100% !important;
          -o-height: 100% !important;
      }
      .carousel .slider-wrapper.axis-horizontal .slider {
          height: 100% !important;
      }
      .carousel.slide{
          height: 100% !important;
          -webkit-height: 100% !important;
          -moz-height: 100% !important;
          -ms-height: 100% !important;
          -o-height: 100% !important;
      }
      .carousel .control-dots {
          margin :10px -23px !important;
      }
      /* ========== END SLIDE STYLE ============*/
      .backgroundTNC {
        background: #262431;
        /* height: 300% !important; */ 
        width: 100% !important;
        min-height: 100% !important;
        position: absolute !important;
        background-size: cover;
        z-index: 2 !important;
    }
    .Header_tnc {
        width: 100%;
        height: 200px;
        background: linear-gradient(306deg, rgba(179,141,255,1) 0%, rgba(22,191,252,1) 100%);
    }
    .title_TNC {
        color: white;
        text-align: center;
        padding-top: 80px;
        font-style: bold;
    }
    .body_tnc {
        color: white;
        padding-top: 40px;
    }
  
      .backgroundFeatures {
          background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573620056/glhitdngudgh4zawsocc.webp') center center no-repeat !important;
          /* background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('./images/empty_bg.png') center center no-repeat !important; */
          height: 100% !important;
          -webkit-height: 100% !important;
          -moz-height: 100% !important;
          -ms-height: 100% !important;
          -o-height: 100% !important;
          width: 100% !important;
          background-size: cover;
          z-index: 2;
      }
      .material,.kaki{
          width: 100% !important;
          z-index: 3;
          margin-top: 40%;
          left: 0;
          padding-left: 2%;
          padding-right: 2%;
      }
      .mata{
          width: 100% !important;
          z-index: 3;
          margin-top: 40%;
          left: 0;
          padding-left: 2%;
          padding-right: 2%;          
      }
      @keyframes speakermove {
          0%{margin-top:185%;}
          100%{margin-top: 40%;}
      }
      .speaker{
        width: 100% !important;
        z-index: 3;
        left: 0;
        margin-top:40%;
        padding-left: 2%;
        padding-right: 2%;
    }
      .mata-mobile, .material-mobile, .tombol-mobile{
          display: none ;
      }
      .tombol,.tombolbelakang{
          width: 100% !important;
          z-index: 3;
          padding-left: 2%;
          padding-right: 2%;
          margin-top: 40%;
          left: 0;
          /* margin-top: 10%; */
      }
      .title_features {
          color: white;
          position: absolute;
          font-size: 3em;
          margin-top: 3%;
          text-align: center;
          /* animation: opacity 10s; */
        }
      .bg-detail {
          z-index: 2;
          margin-top: -20%;
          height: 90% !important;
      }
      .judul-Detail, .Detail {
          color: white;
          padding-right: 20px;
          padding-left: 20px;
          text-align: justify;
      }
      .judul-Detail {
          margin-top: -120%;
          font-size: 3em;
          margin-bottom:5%;
      }
      .Detail {
          font-size: 1.2em;
      }
      .mata-judul-Detail, .material-judul-Detail, .tombol-judul-Detail,.speaker-judul-Detail, .kaki-judul-Detail,.tombolbelakang-judul-Detail {
          color: white;
          font-size: 2.5em;
          font-weight: 600;
          font-family: acumin-pro, sans-serif;
          margin-top: 100%;
      }
      .mata-judul-Detail,.Detail-mata{
        animation-name: mataopacity;
        animation-duration: 2s;
        animation-delay: 5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
      .Detail-mata, .Detail-material, .Detail-tombol, .Detail-speaker, .Detail-kaki, .Detail-tombolbelakang {
          color: white;
          font-size: 1em !important;
          font-family: acumin-pro, sans-serif;
          margin-top: 115%;
          text-align: justify;
          width: 90% !important;
      }
      /* ========== CONTACT STYLE ============*/
      .loadingGif{
          width: 20% !important;
          margin-top:10%;
      }
      .firework_contact {
          width: 10% !important;
          margin-left: -20%;
      }
      .firework_contact1 {
          width: 5% !important;
          margin-left: 30%;
          margin-top: 20%;
          animation: fading 5s infinite;
      }
      .hafiz_contact {
          width: 25% !important;
          margin: 10% 0 0 -10%;
          z-index: 2;
      }
      .title_contact {
          position: absolute !important;
          z-index: 2;
          margin-top: 20%;
          margin-left: 15%;
          color: #fff;
          font-size: 7.5em !important;
          font-family: acumin-pro, sans-serif;
          opacity: 0.8;
          font-weight: 600;
          text-shadow: 2px 10px 14px grey;
      }
      .TNC_button {
          width: 20%;
          padding: 1%;
          margin: 15% 0 0 25%;
          border-radius: 300px;
          background: #efefef;
          color: #5bc0de;
          font-size: 1.4em;
          border: none !important;
      }
      .TNC_button:hover, .PP_button:hover, .CU_button:hover {
          background: #5bc0de;
          color: #efefef;
          border: none !important;
      }
      .PP_button {
          width: 20%;
          padding: 1%;
          margin: 20% 0 0 25%;
          border-radius: 300px;
          background: #efefef;
          color: #5bc0de;
          font-size: 1.4em;
          border: none !important;
      }
      .CU_button {
          width: 20%;
          padding: 1%;
          margin: 25% 0 0 25%;
          border-radius: 300px;
          background: #efefef;
          color: #5bc0de;
          font-size: 1.4em;
          border: none !important;
      }
      .backgroundContact {
        
          background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573467089/dpkmhhdgyhlw00nyysrm.webp') center center no-repeat;
          /* background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('./images/bg_features.png') center center no-repeat; */
          height: 100% !important;
          -webkit-height: 100% !important;
          -moz-height: 100% !important;
          -ms-height: 100% !important;
          -o-height: 100% !important;
          width: 100% !important;
          background-size: cover;
      }
      .awan_contact {
          width: 120% !important;
          margin-left: -20%;
          z-index: 1;
          animation: cloud-animation 14s infinite;
      }
      /* ========== END CONTACT STYLE ============*/
  
      /* ========== COUNTING DOWN STYLE ============*/
      .arus_roket {
       width: 100% !important;
       margin-left: -50%;
       margin-top: -50%;
      }
      .dust_awan_roket {
          margin-left: -50%;
          animation: cloud-animation 10s infinite;
      }    
      .countdown_h5 {
          color: white;
          font-size: 2em;
          margin-top: 25%;
          left: 37%;
          opacity: 0;
          /* margin-left: 65%; */
      }
      .countdown_h5_jam {
          color: orange;
          font-size: 1.5em !important;
          margin-top: 0%;
          margin-left: 35%;
          font-weight: 600;
          background: white;
          border: 2px solid white;
          border-radius: 15px;
          width: 30%;
          padding: 10px;
          -moz-box-align: center;
      }
      .title_form {
          color: #fff;
          font-size: 1.8em;
          font-weight: 600;
          margin: 17% 0 0 8%;
      }
      .form-custom {
          width: 25% !important;
          margin-top: 22% !important;
          margin-left: 7% !important;
          animation: bounce 5s infinite;
      }
      .backgroundCounting {
          background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573696500/njsshv6yeb3h2vpxpplk.webp') center center no-repeat;
          height: 100% !important;
          -webkit-height: 100% !important;
          -moz-height: 100% !important;
          -ms-height: 100% !important;
          -o-height: 100% !important;
          width: 100% !important;
          background-size: cover;
      }
      .button-kirim:hover{
          background-color: lightblue;
          border: 2px solid #007bff;
          color: #007bff;
        }
      .bg_bumi {
          width: 100% !important;
          /* margin: 0; */
          bottom: 0;
          margin-left: -50%;
          animation-delay: 5s;
          animation-name: bg-bumi-complete;
          animation-duration: 10s;
          animation-iteration-count: 1;
          z-index: 3;
      }
      .hafiz_roket_before {
          width: 23% !important;
          margin-top: 10%;
          z-index: 5;
          margin-left: -11%;
          animation: bounce 2s infinite;
          opacity: 0;
      }
      .hafiz_roket {
          width: 25% !important;
          margin-top:13%;
          z-index: 4;
          opacity: 1;
          margin-left: -13% !important;
          animation-name: hafiz-roket;
          animation-duration: 2s;
          -moz-animation-iteration-count: 1;
      }
      @keyframes bg-awan-belakang-roket {
          from {opacity: 0;
          transform: scale(1);}
          to {opacity: 1;transform: scale(1.2)}
      }
      .bg_awan_belakang_roket {
          width: 100% !important;
          opacity: 1;
          margin-left: -50%;
          animation-delay: 5s;
          animation: bg-awan-belakang-roket 10s infinite;
      }
      .bg_awan_tengah_before {
          /* margin-left: -50% !important; */
          left: 0%;
          animation: cloud-animation 10s infinite;
      }
      .bg_awan_tengah_roket {
          width: 100% !important;
          z-index: 5;
          opacity: 0;
          margin-left: -50%;
      }
      .bg_awan_roket {
          width: 100% !important;
          z-index: 2;
          margin-top: -50%;
          opacity: 0;
          position: absolute !important;
      }
      @keyframes fire-roket-move {
          from{
              margin-top: 28%;    
          }
          to {
              margin-top: 31%;
          }
      }
      @keyframes fire-roket {
          from{
              transform: translate(-1px, 0px); 
          }
          to {
              transform: translate(1px, 0px); 
          }
      }
      .fire_roket {
          position: absolute !important;
          width: 14% !important;
          margin-left: -15.8%;
          margin-top: 38%;
          animation: fire-roket 0.3s infinite,
          fire-roket-move 2s 5;
          /* animation-name: fire-roket;
          animation-iteration-count: infinite;
          animation-duration: 0.3s; */
          z-index: 3;
      }
      .fire_roket1 {
          position: absolute !important;
          width: 14% !important;
          margin-left: 0.6%;
          margin-top: 38%;
          animation: fire-roket 0.3s infinite,
          fire-roket-move 2s 5;
          z-index: 3;
      }
      @keyframes bg-bumi-complete {
          from {margin-top: 0%;}
          to {margin-top: 20%;}
      }
      .bg_bumi_complete {
          width: 100% !important;
          margin: 0;
          margin-left: -50%;
          animation-delay: 5s;
          animation: bg-bumi-complete 10s infinite;
          /* animation-name: bg-bumi-complete;
          animation-duration: 10s;
          animation-iteration-count: 1; */
      }
      .digital_after {
          font-family: "digital";
          color: white;
          font-weight: 600;
          margin-top: 19% !important;
          font-size: 8em;
          margin-left: 68% !important;
      }  
      .digital {
          font-family: "digital";
          font-weight: 600;     
          /* margin-left: 50% !important; */
          font-size: 80px !important;
          margin-top: 25% !important;
          color: #ee3d71;
      }  
      /* ========== END COUNTING DOWN STYLE ============*/
  
      /* ========== TALK STYLE ============*/
      .btn_class {
          margin-top: 10% !important;
          z-index: 6;
          width: 10% !important;
          position: absolute !important;
      }
      .hide{
          display: none !important;
      }
      .whiteButton{
          position: absolute !important;
          width: 100% !important;
          margin-left: -50%;
          z-index: 5;       
          opacity: 0.7;
          height: 100% !important;
      }
      .backgroundTalk1 {
          background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573467089/dpkmhhdgyhlw00nyysrm.webp') center center no-repeat;
          height: 100% !important;
          width: 100% !important;
          position: absolute !important;
          background-size: cover;
          z-index: 2 !important;
      }
      .backgroundblack {
          position: absolute !important;
          width: 100% !important;
          height: 100% !important;
          margin-left: -50%;
          z-index: 5;       
          opacity: 0.7;
      }
      .backgroundblackhide {
          background: blue;
      }
      .klik-style {
          position: absolute !important;
          width: 20% !important;
          z-index:6;
          margin-top: 28%;
          margin-left: 1%;
      }
      .talk_button {
          width: 3% !important;
          height: 6% !important;
          z-index: 6;
          margin-left: -2%;
          margin-top: 36.5%;
          border-radius: 600px;
          background: white !important;
          color: grey !important;
          border: none !important;
          position: absolute !important;
          animation: talk-button 2s infinite linear;
      }
      @keyframes column-up {
          0% {
              margin-top: 0;
              -webkit-margin-top: 0;
              -moz-margin-top: 0; 
              -o-margin-top: 0; 
          }
          100% {
              margin-top: -200%;
              -webkit-margin-top: -200%;
              -moz-margin-top: -200%;
              -o-margin-top: -200%; 
          }
      }
      .column {
          margin-top: 0%;
          position: absolute !important;
          width: 20%;
          /* margin-left: 30%;   */
          left: 50%;
          /* animation-name : column-up;
          animation-delay : 25s;
          animation-fill-mode: forwards;
          animation-duration: 30s;
          animation-iteration-count: 1; */
      }
      .description_talk {
          width: 30%;
          color: white !important;
          margin-top: 15%;
          font-family: 'Fredoka One', cursive;
          /* margin-left: -12%; */
          right: 35%;
          font-size: 1.3em;
          text-align: justify;
          z-index: 7;
          position: absolute !important;
      }
      .hafiz_talk {
          position: absolute !important;
          width: 120%;
          /* margin-left: -110%; */
          left: -50%;
          margin-top: 55%;
          z-index: 3;
      }
      @keyframes hafiz-left {
          /* 0%{margin-left: -125%;}
          100% {margin-left: -200%;} */
          0%{left: -50%;}
          100% {left: -120%;}
      }
      .hafiz_talk_left {  
          z-index: 3;      
          width: 120%;
          /* margin-left: -200%; */
          left: -120%;
          margin-top: 20%;
          animation-name: hafiz-left;
          animation-duration: 1.5s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      /* @keyframes head-hafiz {
          from{ margin-top: 400%; }
          to{ margin-top: 50%; }
      } */
      @keyframes head-hafiz {
        from{ opacity: 0; }
        to{ opacity: 1; }
    }
      /* @keyframes head-hafiz-move {
        from{ margin-top: 50%; }
        to{ margin-top: 20%; }
    } */
      .head_hafiz {
        opacity: 0;
          width: 35% !important;
          margin-top: 35%;
          /* margin-top: 400%; */
          position: absolute !important;
          margin-left: -50%;
          animation-name : head-hafiz, head-hafiz-move;
          animation-duration: 2s,1s;
          animation-delay: 1s,7s;
          animation-iteration-count: 1,1;
          animation-fill-mode: forwards,forwards;
      }
      /* @keyframes head-hafiz1 {
          from{ margin-top: 400%; }
          to{ margin-top: 115%; }
      } */
      @keyframes head-hafiz1 {
        from{ opacity: 0; }
        to{ opacity: 1; }
    }
      /* @keyframes head-hafiz1-move {
            from{ margin-top: 115%; }
            to{ margin-top: 75%; }
        } */
      .head_hafiz1 {
        opacity: 0;
          width: 35% !important;
          margin-left: -50%;
          /* margin-top: 400%; */
          margin-top: 90%;
          position: absolute !important;
          animation-name : head-hafiz1,head-hafiz1-move;
          animation-duration: 2s,2s;
          animation-delay: 3s,7s;
          animation-iteration-count: 1,1;
          animation-fill-mode: forwards,forwards;
      }
      /* @keyframes head-hafiz2 {
          0%{ margin-top: 400%;}
          100% { margin-top: 140%;}
      } */
      @keyframes head-hafiz2 {
            0%{ opacity: 0;}
            100% { opacity: 1;}
        }
      .head_hafiz2 {
        opacity: 0;
          width: 35% !important;
          margin-left: -50%;
          margin-top: 145%;
          /* margin-top: 400%; */
          position: absolute !important;
          animation-name : head-hafiz2;
          animation-duration: 2s;
          animation-delay: 5s;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
      }
      @keyframes balon-hafiz3 {
          0%{ margin-top: 200%;}
          100% { margin-top: 195%; }
      }
      .head_hafiz3 {
          width: 35% !important;
          margin-left: -50%;
          margin-top: 195%;
          position: absolute !important;
          /* animation-name : head-hafiz2;
          animation-duration: 3s;
          animation-delay: 14s;
          animation-iteration-count: 1; */
      }
      /* @keyframes head-user {
          0%{ margin-top: 400%;}
          100% { margin-top: 20%}
      } */
      @keyframes head-user {
        0%{ opacity: 0}
        100% { opacity: 1;}
    }
      /* @keyframes head-user-move {
        0%{ margin-top: 20%;}
        100% { margin-top: -30%}
    } */
      .head_user {
        margin-top: 10%;
          width: 35% !important; 
          margin-left: 100%;
          position: absolute !important;
          animation-name: head-user,head-user-move;
          animation-duration: 3s,1s;
          animation-delay: 0s,7s;
          animation-iteration-count: 1,1; 
          animation-fill-mode: forwards,forwards;
      }
      /* @keyframes head-user1 {
          0%{ margin-top: 400%;}
          100% { margin-top: 82%; }
      } */
      @keyframes head-user1-mobile {
        0%{ opacity: 0;}
        100% { opacity: 1;}
    }
      /* @keyframes head-user1-move {
            0%{ margin-top: 82%;}
            100% { margin-top: 50%; }
        } */
      .head_user1 {
        opacity: 0;
          width: 35% !important;
          margin-left: 100%;
          /* margin-top: 400%;           */
          margin-top: 63%;
          position: absolute !important;
          animation-name : head-user1-mobile,head-user1-move;
          animation-duration: 2s,2s;
          animation-delay: 2s,7s;
          animation-iteration-count: 1,1;
          animation-fill-mode: forwards,forwards;
      }
      /* @keyframes head-user2 {
          0%{ margin-top: 400%;}
          100% { margin-top: 150%; }
      } */
      @keyframes head-user2 {
        0%{ opacity: 0;}
        100% { opacity: 1; }
    }
      /* @keyframes head-user2-move {
        0%{ margin-top: 150%;}
        100% { margin-top: 105%; }
    } */
      .head_user2 {
        opacity: 0;
          width: 35% !important;
          margin-left: 100%;
          margin-top: 115%; 
          /* margin-top: 400%; */
          position: absolute !important;
          animation-name : head-user2,head-user2-move;
          animation-duration: 2s,2s;
          animation-delay: 4s,7s;
          animation-iteration-count: 1,1;
          animation-fill-mode: forwards,forwards;
      }
      @keyframes head-user3 {
          0%{ margin-top: 200%;}
          100% { margin-top: 165%; }
      }
      .head_user3 {
          width: 40% !important;
          margin-left: 100%;
          margin-top: 165%;
          position: absolute !important;
          /* animation-name : head-user3;
          animation-duration: 3s;
          animation-delay: 12s;
          animation-iteration-count: 1; */
      }
      /* @keyframes assalamualaikum {
          0%{ margin-top: 400%;}
          100% { margin-top: -10%;}
      } */
      @keyframes assalamualaikum {
        0%{ opacity: 0}
        100% { opacity: 1}
    }
      /* @keyframes assalamualikum-move {
        0%{ margin-top: -10%;}
        100% { margin-top: -60%;}
    } */
      .assalamualaikum {
          width: 90% !important;
          margin-left: 23%;
          margin-top: -17%;
          position: absolute !important;
          animation-name : assalamualaikum, assalamualikum-move;
          animation-duration: 3s,1s;
          animation-delay: 0s,7s;
          animation-iteration-count: 1,1; 
          animation-fill-mode: forwards, forwards;
      }
      /* @keyframes apacitacita {
          from {margin-top:400%;}
          to {margin-top: 120%;}
      } */
      @keyframes apacitacita {
        from {opacity: 0;}
        to {opacity: 1;}
    }
      /* @keyframes apacitacita-move {
        from {margin-top:120%;}
        to {margin-top: 75%;}
    } */
      .apacitacita {
        opacity: 0;
          width: 100% !important;
          margin-left: 10%;
          margin-top: 85%;
          /* margin-top:400%; */
          position: absolute !important;
          animation-name : apacitacita,apacitacita-move;
          animation-duration: 2s,2s;
          animation-delay: 4s,7s;
          animation-fill-mode: forwards,forwards;
          animation-iteration-count: 1,1;
      }
      /* @keyframes apakabar {
          0%{ margin-top: 400%;}
          100% {margin-top: 50%;}
      } */
      @keyframes apakabar {
          0%{opacity: 0;}
          100%{opacity: 1;}
      }
      /* @keyframes apakabar-move {
        0%{ margin-top: 50%;}
        100% {margin-top: 15%;}
    } */
      .apakabar {
        opacity: 0;
          width: 100% !important;
          margin-left:20%;
          margin-top: 30%;
          /* margin-top: 400%; */
          position: absolute !important;
          animation-name : apakabar,apakabar-move;
          animation-duration: 2s,2s;
          animation-delay: 2s,7s;
          animation-iteration-count: 1,1;
          animation-fill-mode: forwards,forwards;
      }
      @keyframes balon-user2 {
          0%{ margin-top: 200%;}
          100% { margin-top: 85%;}
      }
      .balon_user2 {
          width: 100% !important;
          margin-left:28%;
          margin-top: 85%;
          position: absolute !important;
          animation-name : balon-user2;
          animation-duration: 3s;
          animation-delay: 6s;
          animation-iteration-count: 1;
      }
      .balon_user3 {
          width: 100% !important;
          margin-left:20%;
          margin-top: 135%;
          position: absolute !important;
          animation-name : balon-user3;
          animation-duration: 3s;
          animation-delay: 10s;
          animation-iteration-count: 1;
      }
      /* @keyframes walaikumsalam {
        from{ margin-top: 400%; }
        to{ margin-top: 25%; }
        } */
        @keyframes walaikumsalam {
            from{ opacity: 0; }
            to{ opacity: 1; }
            }
    /* @keyframes walaikumsalam-move {
        from{ margin-top: 25%; }
        to{ margin-top: -5%; }
        } */
      .walaikumsalam {
          opacity: 0;
          width: 90% !important;
          margin-left: -12%;
          margin-top: 10%;
          /* margin-top: 400%; */
          position: absolute !important;
          animation-name : walaikumsalam, walaikumsalam-move;
          animation-duration: 2s,1s;
          animation-delay: 1s,7s;
          animation-iteration-count: 1,1;
          animation-fill-mode: forwards,forwards;
      }
      .balon_hafiz_custom {
          width: 100% !important;
          margin-left:-45%;
          margin-top: 60%;
          position: absolute !important;
          /* background: blue; */
      }
      @keyframes balon-hafiz1 {
          0%{ margin-top: 400%;}
          100% { margin-top: 0%; }
      }
      /* @keyframes kalausudahbesar {
          0%{ margin-top: 400%;}
          100% { margin-top: 105%; }
      } */
      @keyframes kalausudahbesar {
          0%{opacity: 0;}
          100% {opacity: 1;}
      }
      .kalausudahbesar {
        opacity: 0;
          width: 100% !important;
          margin-left:-20%;
          margin-top: 110%;
          /* margin-top: 400%; */
          position: absolute !important;
          animation-name: kalausudahbesar;
          animation-duration: 2s;
          animation-delay: 5s;
          animation-fill-mode: forwards;
      }
      /* @keyframes alhamdulillah {
          0%{ margin-top: 400%;}
          100% { margin-top: 80%; }
      } */
      @keyframes alhamdulillah {
        0%{opacity: 0;}
        100% {opacity: 1;}
    }
      /* @keyframes alhamdulillah-move {
            0%{ margin-top: 80%;}
            100% { margin-top: 40%; }
        } */
      .alhamdulillah {
        opacity: 0;
          width: 100% !important;
          margin-left:-10%;
          margin-top: 55%; 
          /* margin-top: 400%; */
          position: absolute !important;
          animation-name: alhamdulillah, alhamdulillah-move;
          animation-duration: 2s,2s;
          animation-delay:3s,7s;
          animation-fill-mode: forwards,forwards;
          animation-iteration-count: 1,1;
      }
      .balon_hafiz3 {
          width: 100% !important;
          margin-left:-25%;
          margin-top: 165%;
          position: absolute !important;
      }
      .bg_awan_talk {
          width: 105%;
          z-index: 2;
          margin-top:22%;
          height: 20px;
          position: absolute !important;
          /* bottom: 0; */
          /* margin-left: -21%; */
          left: -0%;
          opacity: 1;
      }
      .bg_awan {
          width: 100% !important;
          z-index: 2;
          position: absolute !important;
          margin-top: -20%;
          animation: cloud-animation 10s infinite;
      }
      .bg_awan_tengah {
          width: 100% !important;
          z-index: 2;
          position: absolute !important;
          margin-top: -20%;
          animation: cloud-animation 5s infinite;
      }
      /* ========== END TALK STYLE ============*/
  
      /* ========== About STYLE ============*/    
      .cube {
          top: 10vh;
          left: 90vw;
          width: 10px;
          height: 10px;
          border: solid 0.5px #769be6;
          transform-origin: top left;
          transform: scale(0) rotate(0deg) translate(-50%, -50%);
          animation: cube 12s ease-in forwards infinite;
          z-index: 8;
          position: absolute !important;
      }
      .cube:nth-child(2) {
          animation-delay: 2s;
          left: 10vw;
          top: 10vh;
          width: 5px;
          height: 5px;
          position: absolute !important;
      }
      .title_about {
          z-index: 4;
          width: 100%;
          font-family: acumin-pro, sans-serif;
          color: #fff;
          margin-top: 3%;
          text-align: left;
          margin-left:40%;
          margin-right: 20%;
          position: absolute !important;
      }
      .hr_title {
          z-index: 4;
          margin-top: 7%;
          margin-left: 40%;
          border: 2px solid white;
          width: 50%;
          border-radius: 5px;
          position: absolute !important;
      }
      .bi{
          position: absolute !important;
          width: 110% !important;
          height: 110% !important;
          z-index: 2;
          opacity: 1;
          /* animation: fade-in 10s infinite; */
          animation-name: fade-in;
          animation-duration: 7s;
          /* animation-fill-mode: forwards; */
          animation-iteration-count: infinite;
      }
      .bi2{
          position: absolute !important;
          width: 110% !important;
          height: 110% !important;
          z-index: 2;
          opacity: 1;
          /* animation: fade-in 5s infinite; */
          /* animation-fill-mode: forwards; */
          animation-name: fade-in;
          animation-duration: 7s;
          animation-delay: 3s;
          animation-iteration-count: infinite;
      }
      .description {
          width: 50% !important;
          z-index: 4;
          font-family: acumin-pro, sans-serif;
          color: #fff;
          margin-top: 10%;
          text-align: left;
          font-size: 1.6em !important;
          margin-left: 40%;
          animation-name: fade-in;
          animation-duration: 5s;
          animation-iteration-count: 1;
          opacity: 1;
      }
      .wifi_about{
          width: 7% !important;
          z-index: 4;
          margin-top: 25%;
          margin-left: 42%;
          animation-name: scale;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .talk_about{
          width: 7% !important;
          z-index: 4;
          margin-top: 25%;
          margin-left: 48%;
          animation-name: scale;
          animation-delay: 3s;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .onlinecontent_about {
          width: 7% !important;
          z-index: 4;
          margin-top: 25%;
          margin-left: 54%;
          animation-name: scale;
          animation-delay: 6s;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .offlinecontent_about {
          width: 7% !important;
          z-index: 4;
          margin-top: 25%;
          margin-left: 60%;
          animation-name: scale;
          animation-delay: 9s;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .bilingual_about {
          width: 7% !important;
          z-index: 4;
          margin-top: 25%;
          margin-left: 66%;
          animation-name: scale;
          animation-delay: 12s;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .kontenislami_about {
          width: 7% !important;
          z-index: 4;
          margin-top: 25%;
          margin-left: 72%;
          animation-name: scale;
          animation-delay: 15s;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .AI_about {
          width: 7% !important;
          z-index: 4;
          margin-top: 35%;
          margin-left: 57%;
          animation-name: scale;
          animation-delay: 18s;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .song_about {
          width: 7% !important;
          z-index: 4;
          margin-top: 35%;
          margin-left: 63%;
          animation-name: scale;
          animation-delay: 21s;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .alquran_about {
          width: 7% !important;
          z-index: 4;
          margin-top: 35%;
          margin-left: 69%;
          animation-name: scale;
          animation-delay: 24s;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .funemoticon_about {
          width: 7% !important;
          z-index: 4;
          margin-top: 35%;
          margin-left: 75%;
          animation-name: scale;
          animation-delay: 27s;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .ensiklopedia_about {
          width: 7% !important;
          z-index: 4;
          margin-top: 35%;
          margin-left: 81%;
          animation-name: scale;
          animation-delay: 30s;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .matdas_about {
          width: 7% !important;
          z-index: 4;
          margin-top: 35%;
          margin-left: 87%;
          animation-name: scale;
          animation-delay: 33s;
          animation-duration: 3s;
          animation-iteration-count: 1;
          position: absolute !important;
      }
      .layout_about {
          height: 100% !important;
          z-index: 4;
          width: 100% !important;
          margin-left:5%;
          position: absolute !important;
      }
      .backgroundAbout {
        background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573465144/kky9cyln8cyrhcjorte4.webp') center center no-repeat;
        height: 100%;
        width: 100%;
        position: absolute !important;
        background-size: cover;
        z-index: 0;
    }
      /* ========== END About STYLE ============*/
  
      /* ========== HOME STYLE ============*/
      .backgroundLayout {
          background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573469649/bnbs5526f75u7y1ibwmu.webp') center center no-repeat;
          height: 100% !important;
          -o-height: 100% !important;
          -moz-height: 100% !important;
          width: 100% !important;
          position: absolute !important;
          background-size: cover;
          z-index: 0;
      }
      .planet_orange {
          animation: bounce 1.5s infinite;
          margin-left: -130%;
          margin-top: 20%;          
          position: absolute !important;
          width: 150% !important;
          
      }
      .left_cloud {
          animation: cloud-animation 10s infinite;
          width: 150% !important;
          height: auto;
          margin-left: -200%;
          margin-top: 100%;
          position: absolute !important;
      }
      .left_cloud_blur {
          width: 100% !important;
          height: auto;
          margin-left: -20%;
          margin-top: 80%;
          position: absolute !important;
          filter: blur(3px);
          -webkit-filter: blur(3px);
          animation: cloud-animation 15s infinite;
      }
      .right_cloud_blur {
          width: 90% !important;
          height: auto;
          margin-left: -80%;
          margin-top: 80%;
          position: absolute !important;
          filter: blur(3px);
          -webkit-filter: blur(3px);
          animation: cloud-animation 20s infinite;
      }
      .title_home {
          color: #fff;
          width: 100% ;
          font-size: 2em !important;
          line-height: normal;
          /* background: blue; */
          font-weight: 600;
          margin-top: 25% !important;
          left: 0;
          /* margin-left: -55% ; */
          font-family: acumin-pro, sans-serif;
          font-style:  normal;
          position: absolute !important;
      }
      .hafiz_home {
          width: 100%;
          height: auto;
          left: 0%;
          /* top: 50%; */
          /* bottom: 50%; */
          margin-top: 50%;
          position: absolute;
          animation: bounce 3s infinite; 
      }
      .hafiz_baling_baling {
          width:125%;
          height: auto;
          margin-top: 105%;
          left:-63%;
          /* margin-left:-63%; */
          z-index: 2;
          /* position: absolute !important; */
          animation: balingbaling-animation infinite 0.2s linear;
      }
      .center_baling{
          width: 100%;
          height: auto; 
          margin-top: 95%;
          z-index:3;
          position: absolute !important;
          /* margin-left: -50%; */
          left: -0%;
          animation: bounce 3s infinite
      }
      .navigation {
          width: 150% !important;
          position: absolute !important;
          /* margin-left: -70%; */
          left: -20%;
          margin-top: 200%;
          animation: fading 3s infinite linear;
      }
      .right_cloud {
          width: 150% !important;
          position: absolute !important;
          margin-top: 90%;
          margin-left: 50%;
          animation: cloud-animation 10s infinite;
      }
      .planet_ungu {
          position: absolute !important;
          width: 150% !important;
          margin-left: 10%;
          margin-top: 10%;
          animation: bounce 2s infinite;
      }
      /* ========== END HOME STYLE ============*/
      
      .detail_product {
          padding-left: 10%;
          padding-right: 10%;
      }
      @media (min-width: 769px) and (max-width: 1367px) {
        .hafiz_talk{
            width: 100% !important;
            margin-top: 5%;
        }
        .description_talk {
            /* font-family: 'Fredoka One', cursive; */
            position: absolute !important;
            color: white !important;
            margin-top: 20px;
            margin-left: 35%;
            left: 0%;
            font-size: 17px;
            width: 100% !imporant;
            text-align: justify;
        }
        .klik-style {
            position: absolute !important;
            width: 20% !important;
            z-index:6;
            margin-top: 22%;
            /* margin-left: 1%; */
        }
        .talk_button {
            width: 40px !important;        
            height: 40px !important;
            z-index: 6;
            /* margin-left: -5%; */
            margin-top: 31%;
            animation: talk-button 2s infinite linear;
            position: absolute !important;
            background: white !important;
            border: none !important;
            color: gray !important;
            border-radius: 30px;
            font-size: 8px;
        }
        .talk_button_img {
            width: 40px !important;        
            height: 40px !important;
            margin-left: -11.5px;
            margin-top: -6.5px;
        }
      }
      @media (max-width: 768px) {
        /* KEYFRAMES FOR (min-width: 320px) and (max-width: 480px)*/
        /* ========== SLIDE STYLE ============*/
        .carousel.carousel-slider{
            height: 100% !important;
            position: absolute !important;
        }
        .carousel .slider-wrapper {
            height: 100% !important;
        }
        .carousel .slider-wrapper.axis-horizontal .slider {
            height: 100% !important;
        }
        .carousel.slide{
            height: 100% !important;
        }
        /* ========== END SLIDE STYLE ============*/
    
        /* ========== FEATURES STYLE ============*/
        /* .hide-mobile {
            display: none;
        } */
        .hide-desktop {
            visibility: visible !important;
        }
        .awan_mobile {
            right: 0;
            left: 0;
            bottom: 0;
            position: absolute;
        }
        .hr_features {
            z-index: 4;
            margin-top: 15%;
            margin-left: 5%;
            border: 1px solid white;
            width: 55%;
            border-radius: 5px;
            background: blue;
            position: absolute !important;
        }
        .backgroundFeatures{
            background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573464835/tvhfe5tupge1iofc9bxu.webp') center center no-repeat !important;
            height: 100% !important;
            width: 100% !important;
            position: absolute !important;
            background-size: cover;
            z-index: 2 !important;
        }
        .title_features {
            color: white;
            position: absolute;
            font-size: 2em;
            margin-top: 3%;
            text-align: center;
            padding: 0% 5%;
        }
        .mata, .tombol, .material,.speaker,.kaki,.tombolbelakang{
            display: none !important;
        }
        .mata-mobile {
            width: 25% !important;
            margin-left: -48%; 
            margin-top: 25%;
            animation-name: bounce;
            animation-duration: 2s;
            animation-iteration-count: 1;
        }
        .material-mobile {
            width: 25% !important;
            margin-left: -48%; 
            z-index: 3;
            margin-top: 76%;
            opacity: 1;
            animation-name: bounce;
            animation-duration: 2s;
            animation-delay: 2s;
            animation-iteration-count: 1;
        }
        .tombol-mobile{
            width: 25% !important;
            z-index: 3;
            margin-left: -48%; 
            margin-top: 120%;
            opacity: 1;
            animation-name: bounce;
            animation-duration: 2s;
            animation-delay: 4s; 
            animation-iteration-count: 1;
        }
        .bg-detail {
            z-index: 2;
            opacity: 1;
            /* margin-top: -30%; */
            height: 40% !important;
            width: 55% !important;
        }
        .mata-judul-Detail, .material-judul-Detail, .tombol-judul-Detail,.Detail {
            color: white;
            padding-right: 20px;
            padding-left: 20px;
            text-align: justify;
        }
        .mata-judul-Detail, .speaker-judul-Detail {
            margin-top: 25%;
            margin-left: 20%;
            font-size: 1.5em;
            margin-bottom:5%;
            opacity: 1;
        }
        .material-judul-Detail,.kaki-judul-Detail {
            margin-top: 77%;
            margin-left: 20%;
            font-size: 1.5em;
            margin-bottom:5%;
            opacity: 1;
        }
        .tombol-judul-Detail,.tombolbelakang-judul-Detail {
            margin-top: 120%;
            margin-left: 20%;
            font-size: 1.5em;
            margin-bottom:5%;
            opacity: 1;
        }
        /* .Detail {
            font-size: 1em;
            opacity: 1;
            margin-top: 30%;
            margin-left: 40%;
            height: 50% !important;
            width: 55% !important;
        } */
        .Detail-mata, .Detail-speaker {
            color : white;
            margin-top: 35%;
            margin-left: 25%;
            text-align: justify;
            width: 65% !important;
            font-size: 0.7em !important;
        }
        .Detail-material,.Detail-kaki{
            color : white;
            margin-top: 87%;
            margin-left: 25%;
            text-align: justify;
            width: 65% !important;
            font-size: 0.7em !important;
        }
        .Detail-tombol,.Detail-tombolbelakang {
            color : white;
            width: 65% !important;
            margin-top: 130%;
            margin-left: 25%;
            text-align: justify;
            font-size: 0.7em !important;
        }
    
        /* ========== END FEATURES STYLE ============*/
    
        /* ========== CONTACT STYLE ============*/
        .backgroundContact {
            /* background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('./images/background_emptymobile.png') center center no-repeat; */
            background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573696397/buxdeut6wv4jlykybbw2.webp') center center no-repeat;
            height: 100% !important;
            width: 100% !important;
            /* position: absolute !important; */
            background-size: cover;
            z-index: 2 !important;
        }
        .planet_orange_contact {
            position: absolute;
            width: 100% !important;
        }
        .hafiz_contact {
            position: absolute;
            width: 70% !important;
            margin-top: 20%;
            margin-left: 10%;
        }
        .title_contact {
            position: absolute;
            z-index: 2;
            margin-top: 5%;
            margin-left: 28px;
            left: 0%;
            color: #fff;
            font-size: 3.0em !important;
            font-family: acumin-pro, sans-serif;
            text-align: center;
            opacity: 0.8;
            font-weight: 600;
            text-shadow: 2px 10px 14px grey;
    
        }
        .firework_contact{
            width: 50% !important;
            margin-top: 90%;
            left: 30%;
        }
        .firework_contact1{
            width: 20% !important;
            margin-top: 10%;
            /* margin-left: 20%; */
            left: 20%;
        }
        .TNC_button {
            width: 60%;
            padding: 1%;
            margin: 115% 0 0 -30%;
            border-radius: 300px;
            background: #efefef;
            color: #5bc0de;
            font-size: 1.2em;
            border: none !important;
        }
        .TNC_button:hover, .PP_button:hover, .CU_button:hover {
            background: #5bc0de;
            color: #efefef;
            border: none !important;
        }
        .PP_button {
            width: 60%;
            padding: 1%;
            margin: 125% 0 0 -30%;
            border-radius: 300px;
            background: #efefef;
            color: #5bc0de;
            font-size: 1.2em;
            border: none !important;
        }
        .CU_button {
            width: 60%;
            padding: 1%;
            margin: 135% 0 20% -30%;
            border-radius: 300px;
            background: #efefef;
            color: #5bc0de;
            font-size: 1.2em;
            border: none !important;
        }
        .awan_contact {
            display: none !important;
        }
        /* ========== END CONTACT STYLE ============*/
    
        /* ========== COUNTING STYLE ============*/
        .backgroundCounting {
            background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573696551/jlbckwgebfpoxopwu9gv.webp') center center no-repeat;
            height: 120% !important;
            width: 100% !important;
            position: absolute !important;
            background-size: cover;
        }
        .fire_roket {
            width: 42% !important;
            margin-left: -47%;
            margin-top: 117%;
        }
        .fire_roket1 {
            width: 42% !important;
            margin-left: 5%;
            margin-top: 116%;
        }
        .hafiz_roket {
            width: 75% !important;
            margin-left: -37% !important;
            margin-top: 30%;
            animation: bounce 3s infinite;
        }
        .dust_awan_roket {
            width: 140% !important;
            margin-left: -70%;
            margin-top: 40%;
        }
        .bg_awan_tengah_before {
            bottom: 0;
            position: absolute;
        }
        .bg_bumi_complete,.bg_awan_belakang_roket, .bg_awan_tengah_roket,.bg_bumi {
            display: none !important;
        }
        .digital_after {
            color: white;
            font-family: digital;
            font-size: 4.3em;
            font-weight: 600;
            margin-top: 5%;
            margin-left: 8%;
            margin-right: auto;
            text-align: center !important;
        }
        .form-custom {
            width: 90% !important;
            margin-left: 10%;
            margin-top: 145%;
        }
        .button-kirim {
            margin-top: 0%;
        }
        .button-kirim:hover {
            background: #769be6;
        }
        .title_form {
            color: white;
            font-weight: 600;
            font-family: acumin-pro, sans-serif;
            font-size: 1.3em;
            margin-top: 135%;
            margin-left: 10%;
        }
        .hafiz_roket_before {
            width: 75% !important;
            margin-left: -37% !important;
            margin-top: 35%;
        }
        .digital {
            color: ee3d71;
            font-family: digital;
            font-size: 3.7em !important;
            font-weight: 600;
            margin-top: 70% !important;
            /* margin-left: 0% !important; */
            left: 0;
            margin-right: 5%;
        }
        .countdown_h5 {
            color: #fff;
            font-size: 1.6em !important;
            margin-left: 0%;
            margin-top: 2%;
            font-weight: 600;
        }
        .countdown_h5_jam {
            color: orange;
            font-size: 1.5em !important;
            margin-left: 0%;
            margin-top: 0%;
            width: 100%;
        }
        /* ========== END COUNTING STYLE ============*/
    
        /* ========== TALK STYLE ============*/
        .backgroundTalk1 {
            background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573469715/wkia7ezbokobakskucum.webp') center center no-repeat;
            height: 100% !important;
            width: 100% !important;
            position: absolute !important;
            background-size: cover;
            z-index: 2 !important;
        }
        .hide {
            display: none !important;
        }
        .whiteButton{
            position: absolute !important;
            width: 101% !important;
            margin-left: -50%;
            height: 100% !important;
            z-index: 5;       
            opacity: 0.7;
          }
        .talk_button {
            width: 35px !important;        
            height: 35px !important;
            z-index: 6;
            margin-left: -5%;
            margin-top: 120%;
            animation: talk-button 2s infinite linear;
            position: absolute !important;
            background: white !important;
            border: none !important;
            color: gray !important;
            border-radius: 30px;
            font-size: 8px;
        }
        .talk_button_img {
            width: 35px !important;        
            height: 35px !important;
            z-index: 7;
            margin-left: -105%;
            margin-top: -60%;
            /* animation: talk-button 2s infinite linear;
            position: absolute !important;
            background: white !important;
            border: none !important;
            color: gray !important;
            border-radius: 30px;
            font-size: 8px; */
        }
        .klik-style {
            position: absolute !important;
            width: 40% !important;
            z-index:6;
            margin-top: 100%;
            margin-left: 1%;
        }
        .hafiz_talk {
            position: absolute !important;
            width: 200% !important;
            left: 0;
            margin-left: -98%;
            align-items: center;
            align-self: center;
            margin-top: 180px;
            z-index: 3;
        }
        .hafiz_talk_left {
            position: absolute !important;
            width: 200% !important;
            margin-left: 20%;
            /* margin-top: 50%; */
            z-index: 3;
            animation: none;
        }
        .description_talk {
            font-family: 'Fredoka One', cursive;
            position: absolute !important;
            color: white !important;
            margin-top: 18px;
            margin-left: 17px;
            left: 0%;
            font-size: 15px;
            width: 90%;
            text-align: justify;
        }
        .column {
            margin-top: 0%;
            position: absolute !important;
            width: 50% !important;
            margin-left: 3%;
            margin-top: 50%;
            opacity: 1;
            z-index: 4;
        }
        @keyframes head-hafiz-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        @keyframes head-hafiz-mobile-move {
            0% {margin-top: 160%;}
            100% {margin-top: 60%;opacity: 0;}
        }
        .head_hafiz {
            opacity: 0;
            width: 60% !important;
            margin-left: -155%;
            position: absolute !important;
            margin-top: 160%;
            animation-name: head-hafiz-mobile, head-hafiz-mobile-move;
            animation-duration: 2s,2s;
            animation-delay: 2s,5s;
            animation-iteration-count: 1,1;        
            animation-fill-mode: forwards, forwards;
        }
        @keyframes head-hafiz1-mobile-move {
            0% {margin-top: 160%;}
            100% {margin-top: 60%;opacity: 0;}
        }
        @keyframes head-hafiz1-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        .head_hafiz1 {
            opacity: 0;
            margin-top: 400%;
            width: 60% !important;
            margin-left: -155%;
            margin-top: 160%;
            position: absolute !important;
            animation-name: head-hafiz1-mobile, head-hafiz1-mobile-move;
            animation-duration: 2s,2s;
            animation-delay: 5s,9s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        @keyframes head-hafiz2-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        .head_hafiz2 {
            margin-top: 400%;
            width: 60% !important;
            margin-left: -155%;
            margin-top: 160%;
            position: absolute !important;
            animation-name: head-hafiz2-mobile, head-hafiz2-mobile-move;
            animation-duration: 2s,2s;
            animation-delay: 9s,13s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        @keyframes head-hafiz3 {
            from {margin-top: 300%}
            to {margin-top: 195%;}
        }
        .head_hafiz3 {
            width: 35% !important;
            margin-left: -50%;
            margin-top: 195%;
            position: absolute !important;
            /* animation-name: head-hafiz3;
            animation-duration: 2s;
            animation-delay: 19s;
            animation-iteration-count: 1; */
        }
        @keyframes head-user-move-mobile {
            0% {margin-top: 110%;}
            100% {margin-top: 50%;opacity: 0;}
        }
        @keyframes head-user-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        .head_user {
            opacity: 1;
            width: 60% !important;
            margin-left: -10%;
            margin-top: 110%;
            position: absolute !important;
            animation-name: head-user-mobile,head-user-move-mobile;
            animation-delay: 0s,5s;
            animation-duration: 2s,2s;
            animation-iteration-count: 1,1;
            animation-fill-mode: forwards, forwards;
        }
        @keyframes head-user1-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        @keyframes head-user1-mobile-move {
            0% {margin-top: 110%;}
            100% {margin-top: 50%;opacity: 0;}
        }
        .head_user1 {
            opacity: 0;
            width: 60% !important;
            margin-left: -10%;
            margin-top: 110%;
            position: absolute !important;
            animation-name: head-user1-mobile, head-user1-mobile-move;
            animation-duration: 2s,2s;
            animation-delay: 5s,9s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        @keyframes head-user2-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        /* @keyframes head-user2-mobile-move {
            0% {margin-top: 110%;}
            100% {margin-top: 50%;}
        } */
        .head_user2 {
            width: 60% !important;
            margin-left: -10%;
            margin-top: 110%;
            position: absolute !important;
            animation-name: head-user2-mobile,head-user2-mobile-move;
            animation-duration: 2s,2s;
            animation-delay: 9s,13s;
            animation-iteration-count: 1,1;        
            animation-fill-mode: forwards,forwards;
        }
        @keyframes head-user3 {
            from {margin-top: 300%;}
            to {margin-top: 165%;}
        }
        .head_user3 {
            width: 40% !important;
            margin-left: 95%;
            margin-top: 165%;
            position: absolute !important;
            /* animation-name: head-user3;
            animation-duration: 2s;
            animation-delay: 17s;
            animation-iteration-count: 1; */
        }
        /* @keyframes balon-user-move {
            from {margin-top: 65%;}
            to {margin-top: 15%;opacity: 0;}
        } */
        @keyframes assalamualikum-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        @keyframes assalamualikum-mobile-move {
            0% {margin-top:60%}
            100% {margin-top:0%;opacity: 0;}
        }

        .assalamualaikum {
            opacity: 1;
            width: 160% !important;
            margin-left: -150%;
            margin-top: 60%;
            position: absolute !important;
            animation-name: assalamualikum-mobile , assalamualikum-mobile-move;
            animation-delay: 0s,5s;
            animation-duration: 2s,2s;
            animation-iteration-count: 1, 1;
            animation-fill-mode: forwards, forwards;
        }
        @keyframes apakabar-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        @keyframes apakabar-mobile-move {
            0% {margin-top:60%}
            100% {margin-top:0%;opacity: 0;}
        }
        .apakabar {
            opacity: 0;
            width: 160% !important;
            margin-left: -150%;
            margin-top: 60%;
            position: absolute !important;
            animation-name: apakabar-mobile,apakabar-mobile-move;
            animation-duration: 2s,2s;
            animation-delay: 5s,9s;
            animation-iteration-count: 1;        
        }
        @keyframes balon-user2 {
            from {margin-top: 300%;}
            to {margin-top: 85%;}
        }
        .balon_user2 {
            opacity: 0;
            width: 100% !important;
            margin-left:28%;
            margin-top: 85%;
            position: absolute !important;
            animation-name: balon-user2;
            animation-duration: 2s;
            animation-delay: 13s;
            animation-iteration-count: 1;
        }
        .balon_user_custom {
            opacity: 0;
            width: 120% !important;
            margin-left: 40%;
            margin-top: 110%;
            position: absolute !important;
            animation-name : balon-user-custom;
            animation-duration: 3s;
            animation-delay: 16s;
            animation-fill-mode: forwards;
            animation-iteration-count: 1;
        }
        @keyframes balon-user3 {
            from {margin-top: 300%;}
            to {margin-top: 135%;}
        }
        .balon_user3 {
            width: 100% !important;
            margin-left:20%;
            margin-top: 135%;
            position: absolute !important;
            animation-name: balon-user3;
            animation-duration: 2s;
            animation-delay: 17s;
            animation-iteration-count: 1;
        }
        @keyframes  walaikumsalam-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        @keyframes walaikumsalam-mobile-move {
            0% {margin-top: 120%;}
            100% {margin-top: 60%;opacity: 0;}
        }
        .walaikumsalam {
            opacity: 0;
            width: 150% !important;
            margin-left:-105%;
            position: absolute !important;
            margin-top: 120% !important;
            animation-name: walaikumsalam-mobile, walaikumsalam-mobile-move;
            animation-duration: 2s,2s;
            animation-delay: 2s , 5s;
            animation-iteration-count: 1,1;
            animation-fill-mode: forwards, forwards;
        }
        @keyframes balon-hafiz1 {
            from {margin-top: 300%;}
            to {margin-top: 60%;}
        }
        .balon_hafiz1 {
            opacity: 0;
            width: 120% !important;
            margin-left:25%;
            margin-top: 80%;
            position: absolute !important;
            animation-name: balon-hafiz1;
            animation-duration: 2s;
            animation-delay: 11s;
            animation-iteration-count: 1;
        }
        .balon_hafiz11 {
            display: none !important;
            position: absolute !important;
        }

        @keyframes alhamdulillah-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        
        @keyframes alhamdulillah-mobile-move {
            0% {margin-top: 120%;}
            100% {margin-top: 60%;opacity: 0;}
        }
        .alhamdulillah {
            opacity: 0;
            width: 120% !important;
            margin-left:-105%;
            margin-top: 120% !important;
            position: absolute !important;
            animation-name: alhamdulillah-mobile, alhamdulillah-mobile-move;
            animation-duration: 2s,2s;
            animation-delay: 5s,9s;
            animation-iteration-count: 1,1;
            animation-fill-mode: forwards, forwards;
        }
        @keyframes apacitacita-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        
        /* @keyframes apacitacita-mobile-move {
            0% {margin-top: 60%;}
            100% {margin-top: 40%;}
        } */
        .apacitacita {
            opacity: 0;
            width: 160% !important;
            margin-left: -150%;
            margin-top: 60%;
            position: absolute !important;
            animation-name: apacitacita-mobile, apacitacita-mobile-move;
            animation-duration: 2s,2s;
            animation-delay: 9s,13s;
            animation-iteration-count: 1,1;
            animation-fill-mode: forwards,forwards;
        }
        @keyframes kalausudahbesar-mobile {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        .kalausudahbesar {
            width: 120% !important;
            margin-left:-105%;
            margin-top: 120% !important;
            position: absolute !important;
            animation-name: kalausudahbesar-mobile, kalausudahbesar-mobile-move;
            animation-duration: 2s,2s;
            animation-delay: 9s,13s;
            animation-iteration-count: 1,1;
            animation-fill-mode: forwards, forwards;
        }
        @keyframes balon-hafiz3 {
            from {margin-top: 300%;}
            to {margin-top: 165%;}
        }
        .balon_hafiz3 {
            width: 100% !important;
            margin-left:-25%;
            margin-top: 165%;
            position: absolute !important;
            animation-name: balon-hafiz3;
            animation-duration: 2s;
            animation-delay: 19s;
            animation-iteration-count: 1;
        }
        .bg_awan_talk {
            width: 110% !important;
            z-index: 2;
            margin-top:150%;
            height: 20px;
            position: inherit;
            margin-left: -10%;
            display: none !important;
        }
        .bg_awan {
            width: 100% !important;
            z-index: 2;
            position: inherit;
            margin-top: -20%;
            animation: cloud-animation 10s infinite;
            display: none !important;
        }
        .bg_awan_tengah {
            width: 100% !important;
            z-index: 2;
            margin-top: -20%;
            animation: cloud-animation 5s infinite;
        }
        /* ========== END TALK STYLE ============*/
    
        /* ========== About STYLE ============*/
    
        .cube {
            top: 70vh;
            left: 50vw;
            width: 10px;
            height: 10px;
            border: solid 0.5px #769be6;
            transform-origin: top left;
            transform: scale(0) rotate(0deg) translate(-50%, -50%);
            animation: cube 12s ease-in forwards infinite;
            z-index: 5;
            position: absolute !important;
        }
        .cube:nth-child(2) {
            animation-delay: 2s;
            left: 77vw;
            top: 10vh;
            width: 5px;
            height: 5px;
            position: absolute !important;
        }
        .background_about {
            background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573620203/l2wikgjpyn0kykdee4w4.webp') center center no-repeat;
            /* background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('./images/about_mobile.png') center center no-repeat; */
            height: 120% !important;
            width: 101% !important;
            position: absolute !important;
            background-size: cover;
            z-index: 4;
            margin-left:4%;
            margin-top:-25%;
            position: absolute !important;
        }
        .bi{
            width: 200% !important;
            height: 50% !important;
            z-index: 2;
            position: absolute !important;
            margin-top: 0%;
            margin-left: 4%;
            animation-name: fade-in-mobile;
            animation-duration: 5s;
            animation-iteration-count: infinite;
            /* display: none !important; */
        }
        .bi2{
            width: 200% !important;
            height: 50% !important;
            z-index: 2;
            position: absolute !important;
            margin-top: 0%;
            margin-left: 4%;
            animation-name: fade-in-mobile;
            animation-duration: 5s;
            animation-delay: 2s;
            animation-iteration-count: infinite;
            /* display: none !important; */
        }
        @keyframes descript-animate {
            0% {opacity: 0;}
            100% {opacity: 1;}
        }
        .description {
            width: 90% !important;
            z-index: 4;
            font-family: acumin-pro, sans-serif;
            color: #fff;
            position: absolute !important;
            margin-top: 67%;
            text-align: justify;
            font-size: 12.5px !important;
            margin-left: 10%;
            margin-right: 5%;
            position: absolute !important;
            animation-name: descript-animate;
            animation-duration: 5s;
            animation-iteration-count: 1;
        }
        .title_about {
            z-index: 4;
            font-family: acumin-pro, sans-serif;
            color: #fff;
            margin-top: 50%;
            text-align: left;
            font-size: 1.2em;
            margin-left: 30%;
            margin-right: 5%;
            text-align: center;
            width: 69% !important;
            position: absolute !important;
        }
        .hr_title {
            z-index: 4;
            margin-top: 64%;
            margin-left: 35%;
            border: 1px solid white;
            width: 60%;
            border-radius: 5px;
            background: blue;
            position: absolute !important;
        }
        .wifi_about{
            width: 20% !important;
            position: absolute;
            z-index: 6;
            margin-top: 105%;
            margin-left: 5%;
            animation-name: bounce;
            animation-duration: 1s;
            animation-iteration-count: 1;
            position: absolute !important;
        }
        .talk_about{
            width: 20% !important;
            position: absolute;
            z-index: 6;
            margin-top: 105%;
            margin-left: 28%;
            animation-name: bounce;
            animation-delay: 1s;
            animation-duration: 1s;
            animation-iteration-count: 1;
            position: absolute !important;
        }
        .onlinecontent_about {
            width: 20% !important;
            position: absolute !important;
            z-index: 6;
            margin-top: 105%;
            margin-left: 57%;
            animation-name: bounce;
            animation-delay: 2s;
            animation-duration: 1s;
            animation-iteration-count: 1;
        }
        .offlinecontent_about {
            width: 20% !important;
            position: absolute !important;
            z-index: 6;
            margin-top: 105%;
            margin-left: 83%;
            animation-name: bounce;
            animation-delay: 3s;
            animation-duration: 1s;
            animation-iteration-count: 1;
        }
        .bilingual_about {
            width: 20% !important;
            position: absolute !important;
            z-index: 6;
            margin-top: 125%;
            margin-left: 5%;
            animation-name: bounce;
            animation-delay: 4s;
            animation-duration: 1s;
            animation-iteration-count: 1;
        }
        .kontenislami_about {
            width: 20% !important;
            position: absolute !important;
            z-index: 6;
            margin-top: 125%;
            margin-left: 28%;
            animation-name: bounce;
            animation-delay: 5s;
            animation-duration: 1s;
            animation-iteration-count: 1;
        }
        .AI_about {
            width: 20% !important;
            position: absolute !important;
            z-index: 6;
            margin-top: 125%;
            margin-left: 57%;
            animation-name: bounce;
            animation-delay: 6s;
            animation-duration: 1s;
            animation-iteration-count: 1;
        }
        .song_about {
            width: 20% !important;
            position: absolute !important;
            z-index: 6;
            margin-top: 125%;
            margin-left: 83%;
            animation-name: bounce;
            animation-delay: 7s;
            animation-duration: 1s;
            animation-iteration-count: 1;
        }
        .alquran_about {
            width: 20% !important;
            position: absolute !important;
            z-index: 6;
            margin-top: 145%;
            margin-left: 5%;
            animation-name: bounce;
            animation-delay: 8s;
            animation-duration: 1s;
            animation-iteration-count: 1;
        }
        .funemoticon_about {
            width: 20% !important;
            position: absolute !important;
            z-index: 6;
            margin-top: 145%;
            margin-left: 28%;
            animation-name: bounce;
            animation-delay: 9s;
            animation-duration: 1s;
            animation-iteration-count: 1;
        }
        .ensiklopedia_about {
            width: 20% !important;
            position: absolute !important;
            z-index: 6;
            margin-top: 145%;
            margin-left: 57%;
            animation-name: bounce;
            animation-delay: 10s;
            animation-duration: 1s;
            animation-iteration-count: 1;
            /* display : none !important; */
        }
        .matdas_about {
            width: 20% !important;
            position: absolute !important;
            z-index: 6;
            margin-top: 145%;
            margin-left: 83%;
            animation-name: bounce;
            animation-delay: 11s;
            animation-duration: 1s;
            animation-iteration-count: 1;
        }
        .layout_about {
            height:100% !important;
            width: 100% !important;
            z-index: 4;
            position: absolute !important;
            margin-left:5%;
            display: none !important;
            background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573620343/lvy8oyvynhm7sdt6yv4u.webp') center center no-repeat;
        }
        /* ========== About STYLE ============*/
    
        /* ========== HOME STYLE ============*/
        .backgroundLayout {
            /* background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('./images/background_mobile.png') center center no-repeat; */
            background: linear-gradient(to bottom right, rgba(72, 72, 212, 0), rgba(241, 104, 104, 0)), url('https://res.cloudinary.com/pthim/image/upload/v1573620343/lvy8oyvynhm7sdt6yv4u.webp') center center no-repeat;
            height: 120% !important;
            width: 100% !important;
            position: absolute !important;
            background-size: cover;
            z-index: 0;
        }
        .planet_orange {
            animation: bounce 1.5s infinite;
            margin-left: -110%;
            margin-top: 100%;
            position: absolute !important;
            width: 200% !important;
            /* background: blue; */
        }
        .title_home {
            color: #fff;
            font-size: 1.2em !important;
            font-weight: 600;
            margin-top: 80% !important;
            /* left: -60%; */
            margin-left: -94px;
            font-family: acumin-pro, sans-serif;
            width: 300px;
            position: absolute !important;
        }
        .hafiz_home {
            width: 220% !important;
            margin-top: 160%;
            margin-left: -60%;
            /* left: 0%; */
            margin-right: auto;
            position: absolute !important;
            animation: bounce 3s infinite; 
        }
        .left_cloud {
            /* animation: cloud-animation 10s infinite;
            width: 150% !important;
            height: auto;
            margin-left: -90%;
            margin-top: 300%;
            position: absolute !important; */
            display: none;
        }
        .left_cloud_blur {
            /* animation: cloud-animation 15s infinite;
            width: 100% !important;
            height: auto;
            margin-left: -50%;
            margin-top: 200%;
            filter:blur(1px);
            position: absolute !important; */
            display: none !important;
        }
        .hafiz_baling_baling {
            width:170% !important;
            height: auto;
            margin-top: 280%;
            margin-left: 25%;
            z-index: 2;
            position: absolute !important;
            animation: balingbaling-animation infinite 0.2s linear;
        }
        .center_baling{
            width: 150% !important;
            height: auto; 
            margin-top: 290%;
            z-index:3;
            position: absolute !important;
            margin-left: -27%;
            animation: bounce 3s infinite
        }
        .navigation {
            position: absolute !important;
            margin-left: -30%;
            margin-top: 456%;
            width: 200% !important;
            animation: fading infinite 3s linear;
        }
        .planet_ungu {
            position: absolute !important;
            width: 200% !important;
            margin-left: -90%;
            margin-top: 100%;
            animation: bounce 2s infinite;
        }
        .right_cloud {
            /* width: 100% !important;
            position: absolute !important;
            margin-top: 300%;
            margin-left: -50%;
            animation: cloud-animation 10s infinite; */
            display: none;
        }
        .right_cloud_blur {
            /* width: 100% !important;
            position: absolute !important;
            margin-top: 200%;
            margin-left: -50%;
            filter: blur(1px);
            animation: cloud-animation 13s infinite; */
            display: none !important;
        }
        /* ========== END HOME STYLE ============*/
      }
    